import { createStore } from "vuex" 

const store = createStore({
   state:{
      name: "My Vue",
      currentMenu: null,
      sideMenu: true,
      fixMenu: false, 
   }
})

export default store