<template>
  <div class="search_form_container" id="header_search_form">
    <form 
      :action="searchUrl" 
      method="GET"
    >
      <ul>
        <li class="text_input">
          <label>
            Search the Library
          </label>
          <input type="text" name="keyWords" value="" placeholder="Search the Library" />
          
          <fieldset>
            <div class="styled-select" style="display:none;">
              <select 
                v-model="resultsPerPage"
                name="results_per_page"
                id="results_per_page"
              >
                <option value="36">
                  36
                </option>
                <option value="60">
                  60
                </option>
                <option value="120">
                  120
                </option>
                <option value="240">
                  240
                </option>
              </select>
            </div>
          </fieldset>
        </li>
        <li
          @mouseover="mouseOver()"
          @mouseleave="mouseLeave()"
          class="search_menu_button_container show-for-large"
        >
          <a href="javascript:void(0);">
            <span class="font_icon fa fa-chevron-down fa-lg search_menu_button"></span>
          </a>
        </li>
        <li class="search_menu_submit_container">
          <a href="javascript:void(0);" onclick="search_form.submit();">
            <span class="font_icon fa fa-search fa-lg search_button"></span>
          </a>
        </li>
      </ul>
      <div
        v-if="isOpen" 
        @mouseover="mouseOver()"
        @mouseleave="mouseLeave()"
        class="clear search_menu_container"
      >
        <div class="group1">
          Check out the new Intuitive Search by clicking on 'Similar Images' in the search results or in an Image page.
        </div>
        <br />
        <strong>
          Results per page
        </strong>
        <ul class="results_per_page_list options_list">
          <li>
            <a 
              href="javascript:void(0);" 
              class="results_per_page_36"
              :class="{
                'active': resultsPerPage == 36, 
              }"
              @click="handleClick(36)"
            >
              <span class="font_icon fa fa-check fa-lg select_icon"></span> 36
            </a>
          </li>
          <li>
            <a 
              href="javascript:void(0);" 
              class="results_per_page_60"
              :class="{
                'active': resultsPerPage == 60, 
              }"
              @click="handleClick(60)"
            >
              <span class="font_icon fa  fa-check fa-lg select_icon"></span> 60
            </a>
          </li>
          <li>
            <a 
              href="javascript:void(0);" 
              class="results_per_page_120"
              :class="{
                'active': resultsPerPage == 120, 
              }"
              @click="handleClick(120)"
            >
              <span class="font_icon fa fa-check fa-lg select_icon"></span> 120
            </a>
          </li>
          <li>
            <a 
              href="javascript:void(0);" 
              class="results_per_page_240"
              :class="{
                'active': resultsPerPage == 240, 
              }"
              @click="handleClick(240)"
            >
              <span class="font_icon fa fa-check fa-lg select_icon"></span> 240
            </a>
          </li>
        </ul>
        <strong>
          Still cannot find the right image?
        </strong>
        <br />
        Try using the <a :href="interfaceUrl">Interface</a> to request a new image from our photographers.
      </div>
    </form>
  </div>
</template>

<script>
const STATE_INITIAL = 0; 

export default {
  name: 'SearchForm',
  props: {
    searchUrl: String,
    interfaceUrl: String,
    maxRecs: String,
  },
  data: function(){
    return {
      currentState: null,
      isOpen: false,
      resultsPerPage: null,
    }
  },
  computed: {
    isInitial: {
        get () {
            return this.currentState === STATE_INITIAL;
        },
    },
  },
  created: function () {
    this.currentState = STATE_INITIAL;
    this.resultsPerPage = parseInt(this.maxRecs);
  },
  methods: {
    mouseOver() {
      this.isOpen = true;
    },
    mouseLeave() {
      this.isOpen = false;
    },
    handleClick(newResultsPerPage) {
      this.resultsPerPage = newResultsPerPage;
    },
  }
}
</script>
