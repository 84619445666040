<template>
  <div>
    <div id='notes-grid-header' class="grid-x grid-margin-x">
      <div class="small-12 cell">
        <new-note 
          :script-url="scriptUrl" 
          @reload="handleReload()"
        />
      </div>
    </div>
    <div 
      id="notes-grid"
      class="grid-x grid-margin-x mynotes_plugin_notes"
      v-masonry
      transition-duration="0.3s" 
      item-selector=".item"
      >
        <div
          v-masonry-tile
          v-for="note in notes"
          v-bind:key="note.id"
          class="item small-12 medium-4 large-4 cell end"
        >
          <note 
            :data="note" 
            :script-url="scriptUrl" 
            @onUpdate="handleUpdate()"
            @reload="handleReload()"
          />
        </div>
    </div>  
  </div>
</template>

<script>
import NewNote from './NewNote.vue'
import Note from './Note.vue'

const STATE_INITIAL = 0,
    STATE_RUNNING = 1,
    STATE_CANCELLING = 2; 

export default {
  name: 'NotesList',
  props: {
    scriptUrl: String,
  },
  components: {
    Note,
    NewNote,
  },
  data: function(){
    return {
      currentState: null,
      notes: [],
    }
  },
  computed: {
    isInitial: {
        get () {
            return this.currentState === STATE_INITIAL;
        },
    },
    isRunning: {
        get () {
            return this.currentState === STATE_RUNNING;
        },
    },
    isCancelling: {
        get () {
            return this.currentState === STATE_CANCELLING;
        },
    },
  },
  created: function () {
    // console.log("NotesList created");
    this.currentState = STATE_INITIAL;
    this.fetchNotes();
  },
  methods: {
    fetchNotes() {
      const self = this;
      this.axios.get(this.scriptUrl + '/get').then(response => {
        // console.log(response.data.responseResult.data);
        self.notes = response.data.responseResult.data;
        self.onFetchComplete();
      });
    },
    onFetchComplete() {
      
    },
    handleUpdate() {
      // console.log("handleUpdate3");
      // if (typeof this.$redrawVueMasonry === 'function') {
      //   this.$redrawVueMasonry('containerId');
      // }
      
      this.$nextTick(
        function() {
          // this.$redrawVueMasonry('masonryGrid');
          this.$redrawVueMasonry();
        }
      );
    },
    handleReload() {
      this.fetchNotes();
    },
  }
}
</script>
