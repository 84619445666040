<template>
  <div class="small-2x columnsx mobile_nav_control hide-for-large align_right" id="mobile_nav">
    <a 
      href="javascript:void(0);" 
      id="mobile_nav_bt"
      @click="handleClick"
    >
      <span class="font_icon left fas fa-bars fa-lg"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MobileMenuButton',
  computed: {
    currentMenu: {
      get () {
        return this.$store.state.currentMenu;
      },
      set (value) {
        this.$store.state.currentMenu = value;
      },
    },
  },
  created: function () {
    // console.log("MobileMenuButton created");
  },
  methods: {
    handleClick() {
      // console.log("handleClick");
      if (this.currentMenu == 'mobile') {
        this.currentMenu = null;
      } else {
        this.currentMenu = 'mobile';
      } 
    },
  }
}
</script>
