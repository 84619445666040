<template>
  <div>
    <button
      v-if="isInitial"
      @click="handleClickNewNote()"
    >
      Add new note
    </button>
    <div 
      v-if="isEditing || isUpdating"
      class="mynotes_plugin_note header"
    >
      <div class="mynotes_plugin_note_text clearfix">
        <h4 class='standard inlineheader'>
          Add a new note
        </h4>
        <textarea 
          v-model="data.notes"
          :disabled="isUpdating" 
          placeholder="Please enter your notes"
        />
        <br />
        <br />

        <h4 class='standard inlineheader'>
          Add an image
        </h4>
        <div v-if="!imageType">
          <button
            @click="handleClickAddUploadImage()"
            :disabled="!isEditing"
          >
            Upload an image
          </button>
          &nbsp;
          <button
            @click="handleClickLibraryImage()"
            :disabled="!isEditing"
          >
            Use image from Library
          </button>
        </div>
        <div v-if="imageType == 'upload'">
          <p>
            You can add an image by uploading a file from your computer.
          </p>
          <div v-if="data.image">
            <img :src="file.previewImage" style="width:auto;height:200px;" />
          </div>
          <note-upload-box 
            v-if="!data.image"
            doc-type="notes" 
            doc-group="group1"
            doc-file-types="image/jpeg"
            :doc-disabled="!isEditing"
            @select="handleSelect"
          />
        </div>
        <div v-if="imageType == 'library'">
          <p>
            You can add an image by pasting the address of an image in the Millennium Images Library e.g. https://library.milim.com/image?image_number=ABC_123
          </p>
          <div v-if="data.library_image">
            <img :src="'//milimcdn.s3.amazonaws.com/Magnify/' + data.library_image + '.jpg'" alt="Image from Library will appear here" />
          </div>
          <input 
            v-model="library_address"
            type="text" 
            style="margin-bottom:0.9em;"
            placeholder="Enter Library Image URL"
            v-on:keyup="handleChangeLibraryImage"
          />
        </div>
        <div v-if="imageType">
          <button
            @click="handleClickBack()"
            :disabled="!isEditing"
          >
            Back
          </button>
        </div>
        <br />
        <button
          class="align_right"
          @click="handleClickCancel()"
          :disabled="!isEditing"
        >
          Cancel
        </button>
        <button
          class="align_right"
          @click="handleClickSave()"
          :disabled="!isEditing || !isValid"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NoteUploadBox from './NoteUploadBox.vue'
import { upload_service, } from '../file-upload.service';

const STATE_INITIAL = 0,
    STATE_EDITING = 1,
    STATE_UPDATING = 2,
    STATE_DELETED = 3; 

export default {
  name: 'NewNote',
  props: {
    scriptUrl: String,
  },
  components: {
    NoteUploadBox,
  },
  data: function(){
    return {
      currentState: null,
      data: {
        notes: null,
        image: null,
        library_image: null,
      },
      imageType: null,
      library_address: null,
      file: {},
      uploadFieldName: 'notes_documents',
    }
  },
  computed: {
    isInitial: {
        get () {
          return this.currentState === STATE_INITIAL;
        },
    },
    isEditing: {
        get () {
          return this.currentState === STATE_EDITING;
        },
    },
    isUpdating: {
        get () {
          return this.currentState === STATE_UPDATING;
        },
    },
    isDeleted: {
        get () {
          return this.currentState === STATE_DELETED;
        },
    },
    isValid: {
        get () {
          let bool = false;
          if (this.imageType === null) {
            bool = this.data.notes !== null && this.data.notes !== '';
          } else if (this.imageType == 'library') {
            console.log('validate libray image: ' + this.data.library_image);
            bool = (this.data.notes !== null && this.data.notes !== '') && (this.data.library_image !== null && this.data.library_image !== '');
          } else if (this.imageType == 'upload') {
            // console.log('validate libray image: ' + this.data.library_image);
            bool = (this.data.notes !== null && this.data.notes !== '') && (this.data.image !== null && this.data.image !== '');
          }
          return bool;
        },
    },
    // notes: {
    //     get () {
    //       const value = this.data.notes;
    //       const breakTag = '<br />';
    //       return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    //     },
    // },
    // library_image: {
    //   get () {
    //       const value = this.data.notes;
    //       const breakTag = '<br />';
    //       return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    //     },
    // },
  },
  created: function () {
    console.log("Note created");
    this.currentState = STATE_INITIAL;
    // this.fetchNotes();

    // let test = this.getParam('https://library.milim.com/image?image_number=Z543_047');
    // console.log(test);
  },
  methods: {
    handleClickNewNote() {
      this.currentState = STATE_EDITING;
    },
    handleClickCancel() {
      this.currentState = STATE_INITIAL;
      this.reset();
    },
    handleClickSave() {
      this.currentState = STATE_UPDATING;
      if (this.imageType != 'upload') {
        this.save();
      } else {
        this.upload();
      }
    },
    handleClickAddUploadImage() {
      this.imageType = 'upload';
    },
    handleClickLibraryImage() {
      this.imageType = 'library';
    },
    handleClickBack() {
      this.imageType = null;
      this.library_address = null;
      this.file = {};
      this.data.image = null;
      this.data.library_image = null;
    },
    handleChangeLibraryImage(event) {
      this.data.library_image = this.getParam(event.target.value);
      console.log(this.data.library_image);
    },
    handleSelect(file) {
      // console.log('handleSelect');
      // console.log(file);
      this.file = file;
      this.data.image = file.name;
    },
    reset() {
      this.data = {
        notes: null,
        image: null,
        library_image: null,
      };
      this.imageType = null;
      this.library_address = null;
      this.file = {};
    },
    save() {
      const self = this;
      console.log(this.data);
      this.axios.post(this.scriptUrl + '/update', this.data).then(response => {
        // console.log(response.data.responseResult.data);
        // console.log(response.data.responseResult);
        // self.notes = response.data.responseResult.data;
        self.onSaveComplete();
      });
    },
    onSaveComplete() {
      this.currentState = STATE_INITIAL;
      this.reset();
      this.$emit("reload");
    },
    upload() {
      console.log('upload');
      console.log(this.data.notes);
      let formData = new FormData();
      // // Append data to the file to help processing at the backend.
      // formData.append('id', this.file.id);
      formData.append('type', this.file.type);
      formData.append('notes', this.data.notes);
      formData.append(this.uploadFieldName + '[]', this.file.data, this.file.data.name);
      console.log(formData);

      upload_service(formData)
        .then(x => {
            // console.log('then > Upload success');
            // this.uploadedFileslist.push(x.responseResult);
            // this.currentStatus = STATUS_SUCCESS;
            // console.log(this.uploadedFileslist);

            //
            this.onUploadComplete(x.responseResult);
        })
        .catch(err => {
            // console.log('catch > Upload fail');
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
        });
    },
    onUploadComplete(_files) {
      console.log('onUploadComplete');
      this.currentState = STATE_INITIAL;
      this.reset();
      this.$emit("reload");
    },  
    // uploadNote() {
    //     let formData = new FormData();
    //     // const self = this;
    //     // const _files = this.filteredPendingFiles().slice(0, _limit);

    //     // console.log(_files.length + ' - ' + _limit);
    //     // if(_files.length == _limit) {
    //         // console.log('save formData');
    //         // $.each(_files, function(value) {
    //             // console.log(value);
    //             // console.log(_files[value].type);
    //             // console.log(_files[value].name);

    //             // Append data to the file to help processing at the backend.
    //             formData.append('id', this.file.id);
    //             formData.append('type', this.file.type);
    //             formData.append('notes', this.data.notes);
    //             formData.append(this.uploadFieldName + '[]', this.file.data, this.file.data.name);
                
    //             // Update the fileList status.
    //             // self.updateFileStatus('uploading', this.file);
    //         // });

    //         this.upload(formData);
    //     // } else {
    //     //     // console.log('uploading complete');
    //     //     if (this.isUploading) {
    //     //         console.log('All good');
    //     //         this.currentStatus = STATUS_SUCCESS;

    //     //         // Set redirect 
    //     //         setTimeout(function(){ 
    //     //             window.location.replace(submissionsUrl);
    //     //         }, 500);
    //     //     }
    //     // }
    // },
    getParam(address) {
      // var url = 'http:www.google.com';
      const regex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
      const isValid = regex.test(address);

      if (isValid) {
        const url = new URL(address);
        const urlParams = new URLSearchParams(url.search);
        return urlParams.get('image_number');
      }

      return null;
    }
  }
}
</script>
