import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt'
import { VueMasonryPlugin } from "vue-masonry/src/masonry-vue3.plugin";
import store from "./store";
import App from './App.vue'

document.addEventListener('DOMContentLoaded', () => {
    const emitter = mitt();
    let app = createApp(App);
    app.config.globalProperties.emitter = emitter;
    app.use(VueAxios, axios);
    app.use(VueMasonryPlugin);
    app.use(store);
    app.mount('#app');
});