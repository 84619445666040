<template>
  <div class="mynotes_plugin_note">
    <div v-if="data.image">
      <img :src="'//my-milim.s3.amazonaws.com/notes/' + data.account_pid + '/' +data.image" />
    </div>
    <div v-if="data.library_image">
      <img :src="'//milimcdn.s3.amazonaws.com/Magnify/' + data.library_image + '.jpg'" />
    </div>
    <div class="mynotes_plugin_note_text">
      <div v-if="isInitial" v-html="notes">
      </div>
      <div v-if="isEditing || isUpdating">
        <!-- <input 
          v-if="!data.image && !data.library_image"
          v-model="data.library_image"
          type="text" 
          placeholder="Please enter the Library address for the image."
        /> -->
        <textarea 
          v-model="data.notes"
          :disabled="isUpdating" 
          placeholder="Please enter your notes"
        />
      </div>
    </div>
    <ul class="settings_menu mynotes_plugin_note_controls">
      <li v-if="isInitial">
        <button @click="handleClickEdit()">
          Edit
        </button>
      </li>
      <li v-if="isEditing || isUpdating">
        <button 
          @click="handleClickSave()"
          :disabled="isValid"
        >
          Save
        </button>
      </li>
      <li v-if="isEditing || isUpdating">
        <button @click="handleClickDelete()">
          Delete
        </button>
      </li>
    </ul>
    <div class="clear"></div>
  </div>
</template>

<script>
const STATE_INITIAL = 0,
    STATE_EDITING = 1,
    STATE_UPDATING = 2,
    STATE_DELETED = 3; 

export default {
  name: 'Note',
  props: {
    data: Object,
    scriptUrl: String,
  },
  data: function(){
    return {
      currentState: null,
      notes: [],
    }
  },
  computed: {
    isInitial: {
        get () {
            return this.currentState === STATE_INITIAL;
        },
    },
    isEditing: {
        get () {
            return this.currentState === STATE_EDITING;
        },
    },
    isUpdating: {
        get () {
            return this.currentState === STATE_UPDATING;
        },
    },
    isDeleted: {
        get () {
            return this.currentState === STATE_DELETED;
        },
    },
    isValid: {
        get () {
            return this.data.notes === '';
        },
    },
    notes: {
        get () {
          const value = this.data.notes;
          const breakTag = '<br />';
          return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },
    },
    // library_image: {
    //   get () {
    //       const value = this.data.notes;
    //       const breakTag = '<br />';
    //       return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    //     },
    // },
  },
  created: function () {
    console.log("Note created");
    this.currentState = STATE_INITIAL;
    // this.fetchNotes();
  },
  methods: {
    handleClickEdit() {
      this.currentState = STATE_EDITING;
      this.$emit("onUpdate");
    },
    handleClickSave() {
      this.currentState = STATE_UPDATING;
      this.save();
    },
    handleClickDelete() {
      this.currentState = STATE_UPDATING;
      this.delete();
    },
    // run() {
    //   this.currentState = STATE_RUNNING;
    //   this.output.unshift('Run script');
    //   this.fetchScript();
    // },
    // onRunComplete() {
    //   if (this.runOnce || this.isCancelling) {
    //     this.currentState = STATE_INITIAL;
    //     this.output.unshift('Run script complete');
    //   } else {
    //     setTimeout(() => { this.fetchScript(); }, 500);
    //   }
    // },
    // stop() {
    //   if (this.isRunning) {
    //     this.currentState = STATE_CANCELLING;
    //     this.output.unshift('Stopping script');
    //   }
    // },
    save() {
      const self = this;
      // this.axios.delete(this.scriptUrl + '/update', {params: {id: 1}}).then(response => {
      this.axios.post(this.scriptUrl + '/update', this.data).then(response => {
        // console.log(response.data.responseResult.data);
        // console.log(response.data.responseResult);
        // self.notes = response.data.responseResult.data;
        self.onSaveComplete();
      });
    },
    onSaveComplete() {
      this.currentState = STATE_INITIAL;
      this.$emit("onUpdate");
    },
    delete() {
      const self = this;
      this.axios.delete(this.scriptUrl + '/update', {params: {account_pid: this.data.account_pid, id: this.data.id, image: this.data.image}}).then(response => {
        // console.log(response.data.responseResult.data);
        // console.log(response.data.responseResult);
        // self.notes = response.data.responseResult.data;
        self.onDeleteComplete();
      });
    },
    onDeleteComplete() {
      this.currentState = STATE_DELETED;
      this.$emit("reload");
    },
    // nl2br(value) {
    //     // if (!value) return '';
    //     // if (typeof value === 'undefined' || str === null) {
    //     //   return '';
    //     // }
    //     return 'test';
    //     var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    //     return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    // },
  }
}
</script>
