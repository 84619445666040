<template>
    <div>
        <div class="upload-box">
            <input 
                type="file" 
                ref="batch1" 
                :name="docType"
                @change="filesChange($event.target.name, $event.target.files)"
                :accept="docFileTypes" 
                class="input-file"
                :disabled="docDisabled"
            />
            <!-- <p>
                <i class="fas fa-plus"></i>
                Drag your file(s) here or click to browse
            </p> -->
        </div>
        <small>Accepted file types are .jpeg</small>
        <!-- <ul>
            <li 
                v-for="item in fileList"
                v-bind:key="item.name"
            >
                {{item.fileName}} - {{item.status}}
            </li>
	    </ul> -->
    </div>
</template>

<script>
export default {
    // Define a new component called button-counter
    name: 'NoteUploadBox',
    data: function () {
        return {
            // uploadFieldName: 'empty',
            // uploadFileList: [],
            // fileList: [],
            file: {},
        }
    },
    props: [
        'docId', 
        'docType', 
        'docGroup', 
        'docFileTypes', 
        'docDisabled',
    ],
    computed: {
        // fileList: {
        //     get () {
        //         return this.$store.state.form.fileList;
        //     },
        //     set (value) {
        //         this.$store.state.form.fileList = value;
        //     },
        // },
        // uploadFileList: {
        //     get () {
        //         return this.$store.state.form.uploadFileList;
        //     },
        //     set (value) {
        //         this.$store.state.form.uploadFileList = value;
        //     },
        // },
        // uploadedFileslist: {
        //     get () {
        //         return this.$store.state.form.uploadedFileslist;
        //     },
        //     set (value) {
        //         this.$store.state.form.uploadedFileslist = value;
        //     },
        // },
    },
    mounted: function () {
        console.log('Note Upload Box mounted');
        // console.log(this.docType);
        // console.log('mounted:' + this.docGroup);
    },
    unmounted: function () {
        // this.uploadFileList = [];
        this.file = {};
    },
    methods: {
        filesChange(fieldName, fileList) {
            // console.log('filesChange: ' + fieldName);
            // console.log('filesChange: ' + this.docType);
            // console.log('filesChange: ' + this.docGroup);
            // handle file changes
            console.log(fieldName);
            console.log(fileList);

            if (!fileList.length) return;

            var _file
            for(var i = 0; i < fileList.length; i++) {
                let reader = new FileReader;
                reader.onload = e => {
                    // this.previewImage = e.target.result
                    // console.log(e.target.result);
                    this.file.previewImage = e.target.result;
                    console.log(this.file);
                    this.$emit("select", this.file);
                }
                reader.readAsDataURL(fileList[i]);

                // console.log('filesChange: ' + this.docGroup);
                // _file = fileList[i];
                // this.uploadFileList.push(fileList[i]);
                
                this.file = {
                    'id': this.docId,
                    'type': this.docType,
                    'group': this.docGroup,
                    'name': fileList[i].name,
                    'size': fileList[i].size,
                    'status': 'pending',
                    'data': fileList[i],
                };

                // this.fileList.push({
                //     'id': this.docId,
                //     'type': this.docType,
                //     'group': this.docGroup,
                //     'name': fileList[i].name,
                //     'size': fileList[i].size,
                //     'status': 'pending',
                //     'data': fileList[i],
                // });
                // console.log('filesChange: ' + this.docGroup);
                // console.log(this.fileList[0]);
            }
        },
    },
}
</script>