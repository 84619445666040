import * as axios from 'axios';

// const BASE_URL = 'http://localhost/mil.submissions.milim.com/website-mirror/public/submissions';
// const BASE_URL = 'https://milimsearch.com/submissions/';
// console.log(BASE_URL);

function upload_service(formData) {
    // console.log(formData.getAll('milimId[]'))
    // console.log(window.BaseUrl);
    const url = `${window.BaseUrl}/notes/upload`;
    return axios.post(url, 
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        // get data
        // .then(x => {console.log(x.data)})
        // get data
        .then(x => x.data)
        // add url field
        // .then(x => x.map(img => Object.assign({},
        //     img, { url: `${BASE_URL}/images/${img.id}` })));
}

export { upload_service, }