<template>
  <div>
    <button 
      :disabled="isRunning"
      @click="run()"
    >
      <span v-if="runOnce">
        Run Once
      </span>
      <span v-else>
        Run
      </span>
    </button>
    <button
        v-if="!runOnce"
        :disabled="!isRunning"
        @click="stop()"
      >
      Stop
    </button>
    <h3>
      Output
    </h3>
    <p v-if="output">
      <ol reversed>
        <li
          v-for="item in output"
          v-bind:key="item.id"
        >
          {{ item }}
        </li>
      </ol>
    </p>    
  </div>
</template>

<script>
const STATE_INITIAL = 0,
    STATE_RUNNING = 1,
    STATE_CANCELLING = 2; 

export default {
  name: 'ScriptRunner',
  props: {
    scriptUrl: String,
    runOnce: Boolean,
  },
  data: function(){
    return {
      currentState: null,
      output: [],
    }
  },
  computed: {
    isInitial: {
        get () {
            return this.currentState === STATE_INITIAL;
        },
    },
    isRunning: {
        get () {
            return this.currentState === STATE_RUNNING;
        },
    },
    isCancelling: {
        get () {
            return this.currentState === STATE_CANCELLING;
        },
    },
  },
  created: function () {
    this.currentState = STATE_INITIAL;
  },
  methods: {
    run() {
      this.currentState = STATE_RUNNING;
      this.output.unshift('Run script');
      this.fetchScript();
    },
    onRunComplete() {
      if (this.runOnce || this.isCancelling) {
        this.currentState = STATE_INITIAL;
        this.output.unshift('Run script complete');
      } else {
        setTimeout(() => { this.fetchScript(); }, 500);
      }
    },
    stop() {
      if (this.isRunning) {
        this.currentState = STATE_CANCELLING;
        this.output.unshift('Stopping script');
      }
    },
    fetchScript() {
      const self = this;
      this.axios.get(this.scriptUrl).then(function(response){
        self.output.unshift(response.data.responseResult.data);
        self.onRunComplete();
      });
    },
  }
}
</script>
