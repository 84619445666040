<script>
import SearchForm from './components/SearchForm.vue'
import NotesList from './components/NotesList.vue'
import ScriptRunner from './components/ScriptRunner.vue'
import MobileMenuButton from './components/MobileMenuButton.vue'

export default {
  name: 'App',
  components: {
    SearchForm,
    NotesList,
    ScriptRunner,
    MobileMenuButton,
  },
  computed: {
    currentMenu: {
      get () {
        return this.$store.state.currentMenu;
      },
      set (value) {
        this.$store.state.currentMenu = value;
      },
    },
    sideMenu: {
      get () {
        return this.$store.state.sideMenu;
      },
      set (value) {
        this.$store.state.sideMenu = value;
      },
    },
    fixMenu: {
      get () {
        return this.$store.state.fixMenu;
      },
      set (value) {
        this.$store.state.fixMenu = value;
      },
    },
  },
  mounted: function () {
    // console.log('App Created');
    // console.log(this.test);
    // window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted: function () {
    // window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleClickHelp() {
      // console.log('handleClickHelp');
      if (this.currentMenu == 'help') {
        this.currentMenu = null;
      } else {
        this.currentMenu = 'help';
        console.log(this.currentMenu);
      }
    },
    handleClickSettings() {
      // console.log('handleClickSettings');
      if (this.currentMenu == 'settings') {
        this.currentMenu = null;
      } else {
        this.currentMenu = 'settings';
      }
    },
    handleClickSideMenu() {
      console.log('handleClickSideMenu');
      if (this.sideMenu == true) {
        this.sideMenu = false;
      } else {
        this.sideMenu = true;
      }
    },
    handleScroll(e) {
      console.log('handleScroll');
      const doc = document.documentElement;
      // const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
      console.log(top);
      if (top > 40) {
        this.fixMenu = true;
        console.log('fix')
      } else {
        this.fixMenu = false;
      }
    },
  },
}
</script>
